import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import DonationModule from "./modular_assets/DonationModule";
import Presence from "./components/OurPresence";
import About from "./components/About";
import VolunteerModule from "./modular_assets/VolunteerModule";
import { SuccessStoriesModule, SuccessStoriesPage } from "./modular_assets/SuccessStoriesModule";
import SupportDome from "./components/SupportDome";
import Navbar from "./modular_assets/Navbar";
import Footer from "./modular_assets/Footer";
import NotFoundPage from "./components/404";

import "./App.css"

const App = ({ config }) => {
  return (
    <div className="app-container">
      <Navbar config={config} />
      <div className="container-fluid">
      <Router>
        <Routes>
          {config.home.enabled && <Route exact path="/" element={<Home config={config} />} />}
          {config["donation-setting"].enabled && <Route exact path="/donate" element={<DonationModule config={config} />} />}
          {config.presence.enabled && <Route exact path="/presence" element={<Presence config={config} />} />}
          <Route exact path="/about" element={<About />} />
          {config["volunteer-positions"].enabled && <Route exact path="/volunteer" element={<VolunteerModule types={config["volunteer-positions"].data} />} />}
          {config["success-stories"].enabled && (
            <>
              <Route exact path="/success-stories" element={<SuccessStoriesModule stories={config["success-stories"].data} />} />
              <Route exact path="/success-stories/:storyid" element={<SuccessStoriesPage stories={config["success-stories"].data} />} />
            </>
          )}
          {config["support-dome"].enabled && <Route exact path="/support-dome" element={<SupportDome config={config['support-dome']} />} />}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      </div>
      <Footer config={config} />
    </div>
  );
};

export default App;
