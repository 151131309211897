import React from 'react';
import { useParams } from 'react-router-dom';

const SuccessStoriesModule = ({ stories }) => {
  return (
    <div className='container-fluid success-stories-menu'>
      <div className='container-fluid'>
        <div className='row'>
          {stories.map((story, index) => (
            <div key={index} className='col-md-4'>
              <div className='card mb-3 h-100'>
                <img src={story.image} className='card-img-top' alt={`Story ${index} Image`} />
                <div className='card-body'>
                  <h5 className='card-title'>{story.title}</h5>
                  <p className='card-text'>{story.description}</p>
                  <a href={`/success-stories/${index + 1}`} className='btn btn-primary'>
                    Read Full Story
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SuccessStoriesPage = ({ stories }) => {
  const { storyid } = useParams();
  const storyIndex = parseInt(storyid) - 1; // Adjust for 0-based array index

  if (storyIndex < 0 || storyIndex >= stories.length) {
    return <p>Story Not Found</p>;
  }

  const isValidLink = (link) => {
    const validExtensions = ['.png', '.jpeg', '.jpg'];
    const validProtocols = ['https://', 'http://'];

    const isValidExtension = validExtensions.some((ext) => link.toLowerCase().endsWith(ext));
    const isValidProtocol = validProtocols.some((protocol) => link.toLowerCase().startsWith(protocol));

    return isValidExtension && isValidProtocol;
  };

  const story = stories[storyIndex];

  return (
    <div className='success-stories-page'>
    <div className='container'>
      <div className='card mb-3'>
        <img src={story.image} className='card-img-top' alt='Story Image' />
        <div className='card-body'>
          <h5 className='card-title'>{story.title}</h5>
          <p className='card-text'>{story.description}</p>
          {story.data.map((data, dataIndex) => (
            <React.Fragment key={dataIndex}>
              {isValidLink(data) ? (
                <img key={dataIndex} src={data} className='img-fluid' alt='Story Image' />
              ) : (
                <p key={dataIndex}>{data}</p>
              )}
            </React.Fragment>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export { SuccessStoriesModule, SuccessStoriesPage };
