import React, { useState } from "react";

import NotFoundWrapper from "../modular_assets/404Wrapper";

const SupportDome = ({ config }) => {
  const { enabled, data } = config;
  const [enabledSpecies] = useState(data.filter((specie) => specie.enabled));

  if (!enabled) {
    return <NotFoundWrapper />
  }

  return (
    <div className="support-dome-container container-fluid">
      <div className="row">
        {enabledSpecies.map((specie, index) => (
          <div key={index} className="col-md-4">
            <div className="card h-100 mb-3">
              <img src={specie.image} className="card-img-top" alt={`SupportDome ${index} Image`} />
              <div className="card-body">
                <h5 className="card-title">{specie.title}</h5>
                <p className="card-subtitle"><b>Issue: </b>{specie.threats}</p>
                <p className="card-text">{specie.description}</p>
              </div>
              <div className="card-footer text-muted">
                {specie.how_we_help}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SupportDome;
