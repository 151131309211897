import React from 'react';
import NotFoundPage from '../components/404';
import Navbar from './Navbar';
import Footer from './Footer';

const NotFoundWrapper = ({config}) => {
  return (
    <>
    
    <NotFoundPage/>
    
    </>
  );
};

export default NotFoundWrapper;
