/**
 * ATTENTION
 * This service is limited to 50 submissions every month
 * This is fine for most small scale NGO/Charity organisations
 * However in-case you want to increase, the service costs about 5$
 * a month, ie 500INR every month.
 * 
 * Check details at: https://formcarry.com/pricing
 */

import { useState } from "react";
import config from "../manager.json"

export default function Fetch() {

  return (
    <form name="contact" method="POST" data-netlify="true">
   <input className="input" type="text" name="name" placeholder="Your Name" />
  <input type="email" name="email" placeholder="Your Email" />
  <textarea name="message" placeholder="Your Message"></textarea>
  <button type="submit">Submit</button>
      
    </form>
  );
}