// About.js

import React from 'react';

const About = () => {
  return (
    <div className="about-container">
      <h2>About Us</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus
        tristique mauris, non ultrices nunc aliquam id. Fusce commodo mi non
        urna fringilla, at tincidunt neque tempor. In eget mauris vitae nulla
        pharetra ultrices. Ut eget justo vel massa placerat laoreet.
      </p>
      <p>
        Phasellus nec sem non tortor cursus laoreet. Vivamus id leo id neque
        tincidunt scelerisque. Proin sed magna non nunc fringilla pharetra ac
        a mauris. Nam gravida, velit eu cursus vehicula, libero elit
        scelerisque sem, id commodo mi mi nec justo. Curabitur ut eros id
        lectus scelerisque volutpat.
      </p>
    </div>
  );
};

export default About;
