import React from "react";
import ContactForm from "../modular_assets/ContactForm";

const LocationPage = ({ config }) => {
  //const mapUrl = `https://www.google.com/maps/search?q=${config.presence.map.lat},${config.presence.map.long}&key=${"asd"}`;
  const mapUrl =`${config.presence.map.url}` 
  return (
    <div className="location-page-container">

    <div className="container-fluid mt-4">
      <h2>Location and Address</h2>
      <div className="row h-100">
        <div className="col-md-7">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Location Map</h5>
              <div className="map-container">
                <iframe
                  title="Location Map"
                  width="100%"
                  height="400"
                  src={mapUrl}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Contact Information</h5>
              <p>{config.address}</p>
              <p>Phone: {config.phoneno}</p>
              <p>Email: {config.email}</p>
              <p>Website: {config.website}</p>
              {config["form-api-key"] && <ContactForm />}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div >

  );
};

export default LocationPage;
