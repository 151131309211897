import React from "react";


const Footer = ({config})=>{
    return (
        <div className="pg-footer mt-4">
        <footer className="footer">
          <svg
            className="footer-wave-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 100"
            preserveAspectRatio="none"
          >
            <path
              className="footer-wave-path"
              d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
            ></path>
          </svg>
          <div className="">
          <div className="row mb-5 mt-5 g-0">
            <div className="col-md-3">
              
                <a className="navbar-brand footer-logo" href="#">
                  <span className="hidden-link-text">
                    {config.home.dynamic.mission_oneline}{" "}
                  </span>
                  <h1>{config.home.dynamic.title_logo_name}</h1>
                </a>
              
            </div>
            <div className="col-sm-3">
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Our Work</h2>
                <ul id="menu-company" className="footer-menu-list footer-list">
                  {config["success-stories"] ? (
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="/success-stories">Success Stories</a>
                    </li>
                  ) : null}
                  {config["target-species"] ? (
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="/support-dome">Support Dome</a>
                    </li>
                  ) : null}
                  {config["presence"].enabled ? (
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="/presence">Our Presence</a>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Legal</h2>
                <ul id="menu-legal" className="footer-menu-list footer-list">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                    <a href="#">Privacy Notice</a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="#">Terms of Use</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="footer-menu">
                <h2 className="footer-menu-name"> Quick Links</h2>
                <ul id="menu-quick-links" className="footer-menu-list footer-list">
                  
                  {config["volunteer-positions"].enabled ? (
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="/volunteer-positions">Volunteer Positions</a>
                    </li>
                  ) : null}
                  {config["donation-setting"].enabled ? (
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="/donate">Donate</a>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="footer-call-to-action">
                <h2 className="footer-call-to-action-title"> Let's Chat</h2>
                <p className="footer-call-to-action-description">
                  {" "}
                  Have a question?
                </p>
                <a
                  className="footer-call-to-action-button button"
                  href={"mailto:"+config.email}
                  target="_self"
                >
                  {" "}
                  Email Us{" "}
                </a>
              </div>
              <div className="footer-call-to-action">
                <h2 className="footer-call-to-action-title"> You Call Us</h2>
                <p className="footer-call-to-action-link-wrapper">
                  {" "}
                  <a
                    className="footer-call-to-action-link"
                    href={"tel:"+config.phoneno}
                    target="_self"
                  >
                    {" "}
                    {config.phoneno}{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
          </div>
          <div className="footer-copyright">
            <div className="footer-copyright-wrapper">
              <p className="footer-copyright-text">
                <a
                  className="footer-copyright-link"
                  href="https://github.com/godoflogic"
                  target="_blank"
                >
                  {" "}
                  © 2024. | Developed with ❤️ by Olii | Closed distribution
                  under regulated access.
                </a>
                {/**
                 * YOU ARE NOT ALLOWED TO EDIT THE FOOTER COPYRIGHT UNLESS EXPLICIT PERMISSION IN FORM OF EMAIL FROM THE DEVELOPER
                 * REMOVING OR ALTERING THE FOOTER COPYRIGHT LINK (ABOVE) IN BY ANY METHOD OR MEANS WILL MAKE YOU PRONE TO LEGAL ACTION FOR BREACH OF LISCENSE
                 */}
              </p>
            </div>
          </div>
        </footer>
      </div>
    )
}

export default Footer