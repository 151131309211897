import React from "react";


const Navbar = ({config}) =>{
    return (
        <>
        <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            {config.home.dynamic.title_logo_name}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li>
              {config["donation-setting"].enabled ? (
                <li className="nav-item">
                  <a
                    className="btn-contrast nav-link active btn"
                    aria-current="page"
                    href="/donate"
                  >
                    Donate
                  </a>
                </li>
              ) : null}
              {config["presence"].enabled ? (
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/presence"
                  >
                    Our Presence{" "}
                  </a>
                </li>
              ) : null}
             {config["volunteer-positions"].enabled ? (
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/volunteer"
                  >
                    Volunteer{" "}
                  </a>
                </li>
              ) : null}
              {config["support-dome"].enabled ? (
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/support-dome"
                  >
                    Support Dome{" "}
                  </a>
                </li>
              ) : null}
            </ul>
          
          </div>
        </div>
      </nav></>
    )
}


export default Navbar