import React from "react";
import RandomCardComponent from '../modular_assets/RandomHomeCards';

const Banner = ({ slides }) => {
  return (
    <div className="banner-container">
      <div id="homecarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {slides.map((slide, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <img src={slide} className="carousel-image" alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#homecarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#homecarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

const SupportDome = ({ config }) => {
  return (
    <div className="support-dome-container">
      <h2 className="text-center mt-5">Support Dome</h2>
      <RandomCardComponent items={config['support-dome'].data} morelink={"/support-dome"} btntxt={"Know More"} />
    </div>
  );
};

const DonationModule = ({ config }) => {
  return (
    <div className="donation-module-container">
      <h2 className="text-center mb-4">Support Our Cause</h2>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Donate Now</h5>
              <p className="card-text">Your donation can make a difference. Click below to support our cause.<br />We accept more than just monetary donations!</p>
              <a href="/donate" className="btn btn-sm btn-block btn-donate">Donate</a>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Volunteer</h5>
              <p className="card-text">Not able to donate? Consider volunteering your time and skills to help us.</p>
              <a href="/volunteer" className="btn btn-sm btn-block btn-secondary">Volunteer</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuccessStory = ({ config }) => {
  return (
    <div className="success-story-container">
      <h2 className="text-center mt-5">Success Stories</h2>
      <RandomCardComponent items={config['success-stories'].data} morelink={"/success-stories"} btntxt={"See All"} />
    </div>
  );
};

const VolunteerSection = ({ config }) => {
  return (
    <div className='volunteer-section-container'>
      <h2 className='text-center mt-5'>Volunteer with us.</h2>
      <RandomCardComponent items={config['volunteer-positions'].data} morelink={"/volunteer"} btntxt={"See All"} />
    </div>
  );
};

const HomeApp = ({ config }) => {
  return (
    <div className="home-app-container">
      {config.home.banner.enabled && <Banner slides={config.home.banner.data} />}
      {config['support-dome'].enabled && <SupportDome config={config} />}
      {config['donation-setting'].enabled && <DonationModule />}
      {config['success-stories'].enabled && <SuccessStory config={config} />}
      {config['volunteer-positions'].enabled && <VolunteerSection config={config} />}
    </div>
  );
};

export default HomeApp;
