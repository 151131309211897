import React, { useState } from "react";
import QRCode from "qrcode.react"; 

const DonationModule = ({ config }) => {
  const { platforms, default_currency, enabled, description } =
    config["donation-setting"];
  const enabledPlatforms = Object.keys(platforms).filter(
    (platform) => platforms[platform].enabled
  );
  const defaultPlatform =
    enabledPlatforms.length > 0 ? enabledPlatforms[0] : null;
  const [selectedPlatform, setSelectedPlatform] = useState(defaultPlatform);
  const [selectedCurrency, setSelectedCurrency] = useState(default_currency);

  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    setSelectedCurrency(default_currency);
  };

  const handleDonate = () => {
    if (selectedPlatform === "ewp") {
      platforms.ewp.data
        .filter((item) => item.enabled)
        .forEach((item) => window.open(item.link, "_blank"));
    } else {
      const { base_link, currencies } = platforms[selectedPlatform];
      const buttonId = currencies[selectedCurrency].hosted_button_id;
      window.open(base_link + buttonId, "_blank");
    }
  };

  if (!enabled) {
    return <div>Donation module is not enabled.</div>;
  }

  return (
    <div className="donation-module container card card-body mt-2 mb-2 ">
      <h2>Donate Now</h2>
      <p>{description}</p>
      <p>Select a platform to donate:</p>
      <ul className="list-group">
        {enabledPlatforms.map((platform) => (
          <li
            key={platform}
            className={`list-group-item ${
              selectedPlatform === platform ? "active" : ""
            }`}
            onClick={() => handlePlatformChange(platform)}
          >
            {platforms[platform].ui_text}
          </li>
        ))}
      </ul>

      {selectedPlatform !== "ewp" && selectedPlatform !== "bhim upi" && (
        <>
          <p>Select currency:</p>
          <select
            className="form-select mb-3"
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
          >
            {Object.keys(platforms[selectedPlatform].currencies)
              .filter(
                (currency) =>
                  platforms[selectedPlatform].currencies[currency].enabled
              )
              .map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
          </select>
        </>
      )}

      {(selectedPlatform === "paypal" || selectedPlatform === "stripe") && (
        <div className="mt-3">
          <button className="btn btn-primary" onClick={handleDonate}>
            <i className={`fab fa-${selectedPlatform} me-1`} />
            {selectedPlatform === "paypal" ? "PayPal" : "Stripe"}
          </button>
        </div>
      )}

      {selectedPlatform === "bhim upi" && (
        <div className="mt-3">
          <p>This is an INR only payment option.</p>
          <p>
            Please scan the QR code or use the provided VPA to make a donation:
          </p>
          <QRCode value={platforms['bhim upi'].base_link} />
          <p className="mt-2">
            <strong>VPA:</strong> {platforms['bhim upi'].base_link}
          </p>
        </div>
      )}

      {selectedPlatform === "ewp" && (
        <div className="mt-3">
          {platforms.ewp.data
            .filter((item) => item.enabled)
            .map((item) => (
              <a
                key={item.title}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary me-2"
              >
                {item.title}
              </a>
            ))}
        </div>
      )}
      {config["donation-setting"].nonmon.enabled && (
        <div className="mt-3">
          <h3>Non-Monetary Donations</h3>
          <p>
            We also accept non-monetary donations like pet food, toys, etc. For
            more information, please contact us at
            <strong> {config.email}</strong>.
          </p>
        </div>
      )}
    </div>
  );
};

export default DonationModule;
