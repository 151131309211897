import React from 'react';

const RandomCardComponent = ({ items, morelink, btntxt }) => {
  const getRandomItems = () => {
    const shuffled = items.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const randomItems = getRandomItems();

  return (
    <div className='random-card-container'>
    <div className="row">
      {randomItems.map((item, index) => (
        <div key={index} className="col-md-4">
          <div className="card h-100">
            {item.image ? (<>
                <img src={item.image} className="card-img-top" alt="card image"/>
            </>) : (<></>)}
          
            <div className="card-body">
              <h5 className="card-title">{item.title}</h5>
              <p className="card-text">{item['description']}</p>
            </div>
          </div>
        </div>
      ))}
      <div className="col-md-12 text-center mt-3">
        <a href={morelink} className="btn btn-primary">{btntxt}</a>
      </div>
    </div>
    </div>
  );
};

export default RandomCardComponent;
