import React from "react";

const VolunteerModule = ({ types }) => {
  return (
    <div className="volunteer-module container-fluid">
      <h2>Volunteer Opportunities</h2>
      <div className="row">
        {types
          .filter((type) => type.enabled)
          .map((type, index) => (
            <div className="col-md-4 mb-3" key={index}>
              <div className="card h-100 shadow-sm">
                <div className="card-body mb-3 d-flex flex-column">
                  <h3 className="card-title">{type.title}</h3>
                  <p className="card-text">{type.description}</p>
                  <p className="card-text">
                    <strong>Requirements:</strong> {type.requirements}
                  </p>
                  <p className="card-text">
                    <strong>Location:</strong> {type.location}
                  </p>
                  <a href={type["enquiry-redirect"]} className="btn btn-primary mt-auto invisible btn-hidden-cust fixed-bottom">
                    Enquire
                  </a>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default VolunteerModule;
